/* stylelint-disable css-modules/no-global-scoped-selector */
@import './responsive';
@import './commonStyling';
@import './toastifyOverride';
@import './reset';

.globalErrorBorder {
  border: 1px solid $errorMiddle !important;
  color: $errorMiddle;
  * {
    color: $errorMiddle !important;
  }
}
.globalValidBorder {
  border: 1px solid $successMiddle !important;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  color: $fontsPrimary;
  @extend .fontPrimaryNormal;
}

button,
a {
  cursor: pointer;
  @extend .fontPrimaryNormal;
  border: none;
  background: transparent;
  text-decoration: none;
  color: initial;
  &:active,
  &:focus {
  }
}
