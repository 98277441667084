@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.roadMapsWrap {
  .item {
    padding: 6px 16px 6px 3px;
    display: flex;
    align-items: center;
    &:hover {
      border-radius: 8px;
      background-color: $grayscale50;
      .dragIndicator {
        visibility: visible;
      }
      .icons {
        visibility: visible;
      }
    }
    .content {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
    }
    .titleWrap {
      display: flex;
      align-items: center;
      form {
        width: 100%;
      }
    }
    .checkbox {
      width: 20px;
      margin-right: 6px;
      input {
        width: 20px;
        height: 20px;

        &:disabled {
          cursor: default;

          &:before {
            box-shadow: inset 0 0 0 2px $grayscale200;
          }
        }

        &:before {
          box-shadow: inset 0 0 0 2px $grayscale400;
        }

        &:checked::before {
          box-shadow: none;
        }
      }
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      cursor: default;
    }
    .dragIndicator {
      visibility: hidden;
      font-size: 18px;
      color: $grayscale400;
    }
    .icons {
      visibility: hidden;
      font-size: 20px;
      color: $primary;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      z-index: 1;
      background: linear-gradient(270deg, $grayscale50 92.19%, rgba(248, 250, 252, 0.00) 102.81%);
      padding: 0 0 0 16px;
      top: 0;
      bottom: 0;
    }
  }
  .notDraggable {
    .dragIndicator {
      visibility: hidden !important;
    }
    .icons {
      visibility: hidden !important;
    }
  }
  .completed {
    &:hover {
      .dragIndicator {
        visibility: hidden;
      }
    }
    .title {
      color: $primary !important;
    }
    .icons {
      visibility: hidden;
    }
  }
  .abandoned {
    &:hover {
      .dragIndicator {
        visibility: hidden;
      }
      .icons {
        visibility: visible;
      }
    }
    .title {
      color: $grayscale400 !important;
    }
  }
  .typing {
    &:hover {
      background-color: transparent;
      .dragIndicator {
        visibility: hidden;
      }
    }
    .icons {
      display: none;
    }
    .typingInput {
      border: none;
      padding: 0;
      width: 100%;
      input {
        height: auto;
        font-size: 12px;
        font-weight: 500;
        line-height: 140%;
        padding: 0;
      }
    }
    .titleWrap {
      width: 100%;
    }
  }
  .filter {
    div[role='button'] {
      padding: 8px 28px 8px 12px !important;
    }
  }
  .filterSelected {
    fieldset {
      border: 1px solid $primary;
      background: $primary5;
    }
  }
  .trackerWrap {
    max-height: 300px;
    overflow-y: auto;
  }
  .selected {
    background: $grayscale50;
    border-bottom: 1px solid $grayscale500;
    .dragIndicator {
      visibility: visible;
    }
    .icons {
      visibility: visible;
    }
  }
  .disableHover {
    &:hover {
      border-radius: 8px;
      background-color: transparent;
      .dragIndicator {
        visibility: hidden;
      }
      .icons {
        visibility: hidden;
      }
    }
  }
  .goalWrap {
    display: block;
    padding: 0;
    &:hover {
      border-radius: 8px;
      background-color: transparent;
      .dragIndicator {
        visibility: hidden ;
      }
      .icons {
        visibility: hidden;
      }
    }
    .goal {
      padding: 6px 16px 6px 3px;
      &:hover {
        border-radius: 8px;
        background-color: $grayscale50;
        .dragIndicator {
          visibility: visible;
        }
        .icons {
          visibility: visible;
        }
      }
    }
    &.completed {
      &:hover {
        .dragIndicator {
          visibility: hidden;
        }
      }
    }
    &.abandoned {
      &:hover {
        .dragIndicator {
          visibility: hidden;
        }
      }
    }
  }
  .goal {
    @extend .lytFlexBetween;
    .checkbox {
      display: none;
    }
    &:hover {
      .checkbox {
        display: block;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 700;
      color: $grayscale700;
    }
    .typingInput {
      input {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .toggleGoal {
      cursor: pointer;
      user-select: none;
      outline: none;
      margin-right: 4px;
      color: $grayscale400;
      transform: rotate(180deg);
    }
    .toggleGoalDefault {
      cursor: default;
      color: $grayscale300;
    }
    .toggleGoalOpened {
      transform: rotate(0deg);
    }
  }

  .trackerWrapOpen {
    height: auto;
  }
  .trackerWrap {
    max-height: 400px;
  }
  .emptyList {
    @extend .lytFlexCenter;
    padding: 24px 0;
    font-size: 16px;
    font-weight: 600;
    height: 300px;
    background: $grayscale50;
    color: $grayscale500;
    margin-top: 16px;
  }
}
.goalTodos {
  .item {
    .dragIndicator {
      visibility: hidden !important;
    }
    .icons {
      visibility: hidden !important;
    }
    &:hover {
      .icons {
        visibility: visible !important;
      }
    }
  }
}
