@import 'src/styles/responsive';
@import 'src/styles/commonStyling';

.inputContainer {
  width: 100%;
  @extend .lytFlexRow;
  align-items: center;
  .select {
    @extend .fontPrimaryNormal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    div[role='button'] {
      & > span {
        display: block;
        width: 0;
        min-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        z-index: 99;
      }
      color: $grayscale400;
      font-weight: 500;
    }

    &[data-selected='true'] {
      div[role='button'] {
        color: $grayscale900;
        font-weight: 500;
      }
    }
  }
  .disabled {
    background: $grayscale100;
    color: $grayscale600;
    div[role='button'] {
      color: $grayscale400;
    }
  }
  fieldset {
    border: 1px solid $grayscale300;
    border-radius: 4px;
    height: auto;
  }
  .icon {
    color: $grayscale500;
    pointer-events: none;
    position: absolute;
    right: 8px;
  }

  .iconDisabled {
    color: $grayscale300;
  }

  .iconExpanded {
    transform: rotate(180deg);
  }

  .iconClose {
    margin-right: 32px;
    cursor: pointer;
    pointer-events: auto;
    z-index: 1;
  }

  .smallIcon {
    font-size: 16px;
  }

  .small {
    div[role='button'] {
      padding: 4px 28px 4px 8px;
    }
    font-size: 12px;
  }

  .normal {
    div[role='button'] {
      padding: 14px 32px 14px 17px;
    }
  }

  .success {
    fieldset {
      border: none !important;
    }
    div[role='button'] {
      background-color: #eafff7;
      & > span {
        color: #008869;
      }
    }
  }

  .iconSuccess {
    color: #008869;
  }

  .warning {
    fieldset {
      border: none !important;
    }
    div[role='button'] {
      background-color: $alert50;
      & > span {
        color: $alertDark;
      }
    }
  }

  .iconWarning {
    color: $alertDark;
  }
  .rounded {
    fieldset {
      border-radius: 80px;
    }
    div[role='button'] {
      border-radius: 80px;
    }
  }
  .roundedDisabled {
    border-radius: 80px;
  }
}

.tags {
  border-bottom: 1px solid $grayscale200;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  box-sizing: border-box;
}

.search {
  background-color: #fff;
}

.gradient {
  position: relative;
  border-radius: 16px;
  background: radial-gradient(
    86.32% 224.44% at 4.7% 7.78%,
    rgba(255, 175, 187, 0.05) 0%,
    rgba(255, 175, 187, 0.05) 20%,
    rgba(58, 160, 182, 0.05) 40%,
    rgba(87, 202, 225, 0.05) 60%,
    rgba(24, 138, 161, 0.05) 70.31%,
    rgba(115, 102, 217, 0.05) 81.77%,
    rgba(225, 194, 255, 0.05) 100%
  );
  div[role='button'] {
    color: $grayscale400;
    font-weight: 500;
  }

  &[data-selected='true'] {
    div[role='button'] {
      color: $grayscale900;
      font-weight: 500;
    }
  }

  fieldset {
    border-radius: 13px !important;
    border: none;
  }

  &::after {
    position: absolute;
    border-radius: 13px;
    z-index: -1;
    content: '';
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: white;
  }

  &::before {
    position: absolute;
    border-radius: 13px;
    z-index: -1;
    content: '';
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: radial-gradient(
      rgba(255, 175, 187, 1),
      rgba(255, 175, 187, 0.48),
      rgba(58, 160, 182, 0.31),
      rgba(87, 202, 225, 1),
      rgba(24, 138, 161, 0.24),
      rgba(115, 102, 216, 0.31),
      rgba(225, 194, 255, 1)
    );
  }
}
