@import '../../styles/commonStyling.scss';
@import '../../styles/responsive.scss';

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  position: relative;
  color: $grayscale500;
  font-size: 16px;
  font-weight: 600;
  height: 64px;
  padding: 0 6px;
  @extend .lytFlexCenter;
  .menuIcon {
    @include media('<large') {
      display: none;
    }
    color: inherit;
    margin-right: 14px;
    font-weight: 500;
  }
}

.linkActive {
  color: $secondary;
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    height: 2px;
    width: 100%;
    background-color: $secondary;
  }
}
