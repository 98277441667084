@import 'src/styles/commonStyling';
@import 'src/styles/responsive';

.inputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.inputRow {
  @extend .lytFlexRow;
  flex: 1 1 0;
  gap: 24px;

  .halfRow {
    flex: 1 1 0;
  }
}
.autosavedBoxWrap {
  @extend .lytFlexRow;
}

.autosavedBox {
  display: flex;
  align-items: center;
  color: $grayscale400;
  margin-left: auto;
  margin-right: 16px;
}

.saveOrDiscard {
  width: 100%;
  @extend .lytFlexRow;
  justify-content: space-between;
  align-items: center;

  .btnWrap {
    button {
      width: 20px;

      span {
        color: $primary;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
  }

  .submit {
    width: 120px;
  }
}

.textArea {
  @extend .borderDefault;
}

.deleteNoteModal {
  > h3 {
    font-size: 20px !important;
  }
  & > div {
    margin-top: 0 !important;
  }
}
